document.addEventListener("DOMContentLoaded", () => {
  const targets = document.querySelectorAll(".a-targets")
  const play = document.querySelector("#play-overlay")
  const startButton = document.querySelector("#start")
  const errorDialog = document.querySelector("#error")
  const errormsg = document.querySelector("#errormsg")

  //FUNCTIONS
  let hide = (element) => {
    if (!element.classList.contains("visuallyhidden")) {
      element.classList.add("visuallyhidden")
      setTimeout(() => {
        element.classList.add("hidden")
      }, 200)
    }
  }

  let show = (element) => {
    if (element.classList.contains("hidden")) {
      element.classList.remove("hidden")
      setTimeout(() => {
        element.classList.remove("visuallyhidden")
      }, 200)
    }
  }

  const sceneEl = document.querySelector("a-scene")
  let arSystem

  sceneEl.addEventListener("loaded", function () {
    console.log("Scene has loaded")
    setTimeout(() => {
      arSystem = sceneEl.systems["mindar-image-system"]
      startButton.classList.remove("locked")
      startButton.innerHTML = "Let's go"
      // hide(document.querySelector("#loading-sentence"))
    }, 1000)
  })

  //START
  startButton.addEventListener("click", () => {
    if (!startButton.classList.contains("locked")) {
      console.log("start arSystem")
      arSystem.start() // start AR
      hide(document.querySelector("#welcome"))
      // console.log(document.querySelector("a-assets").fileLoader)
    }
  })

  sceneEl.addEventListener("arError", (event) => {
    console.log(event)
    errormsg.innerHTML = event.detail.error
    hide(document.querySelector("#loading"))
    show(errorDialog)
  })

  //INITIALIZE
  play.addEventListener("click", () => {
    let active_video = document.querySelector(".active-video")
    active_video.play()
    console.log("playing: " + active_video.src)
    active_video.addEventListener("ended", (event) => {
      play.classList.remove("hidden")
    })
    play.classList.add("hidden")
  })

  targets.forEach((elem) => {
    elem.addEventListener("targetFound", (event) => {
      console.log("targetFound: " + elem)
      play.classList.remove("hidden")
      document
        .querySelector(
          elem.getElementsByTagName("a-video")[0].getAttribute("src")
        )
        .classList.add("active-video")
    })

    elem.addEventListener("targetLost", (event) => {
      console.log("targetLost: " + elem)
      play.classList.add("hidden")
      document.querySelector(".active-video").pause()
      document.querySelector(".active-video").classList.remove("active-video")
    })
  })
})
